import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/kai/syncthing/sync/current/jst-presto-2021-accessibility/web/accessible-iot-source/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
import { format } from 'date-fns';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const SiteMetaData = makeShortcode("SiteMetaData");
const Fragment = makeShortcode("Fragment");
const Heading = makeShortcode("Heading");
const Alert = makeShortcode("Alert");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const SourceTags = makeShortcode("SourceTags");
const Flex = makeShortcode("Flex");
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Grid sx={{
      mb: 6
    }} mdxType="Grid">
      <SiteMetaData mdxType="SiteMetaData">
  {siteMetadata => {
          const {
            name,
            description
          } = siteMetadata;
          return <Fragment mdxType="Fragment">
        <Heading variant="styles.h1" mdxType="Heading">{name}</Heading>
      </Fragment>;
        }}
      </SiteMetaData>
      <Grid sx={{
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr'
      }} mdxType="Grid">
      </Grid>
      <Alert variant="success" mdxType="Alert"> Towards Accessibility Assessment Toolkits for the Internet of Things</Alert>
    </Grid>
    <Grid sx={{
      gap: 0,
      mb: 6
    }} mdxType="Grid">
      <h2>{`About`}</h2>
      <p>{`This page is an effort to provide accessibility assessment tools to the Internet of Things.`}</p>
      <p>{`Our first effort is to work on an accessibility assessment Toolkits for Inclusive IoT Design using Onbody Sensing.`}</p>
      <p>{`We propose to build Open Accessibility Assessment Toolkits for Inclusive IoT Design that will provide a foundation for evaluating diverse IoT services in terms of user-perceived accessibility first using on-body sensing. We provide the first steps to an Inclusive IoT architecture enabling an in-situ, dynamic system reconfiguration based on the user’s implicit feedback.`}</p>
    </Grid>
    <Grid sx={{
      gap: 0,
      mb: 6
    }} mdxType="Grid">
      <h2>{`why toolkits?`}</h2>
      <p>{`Currently accessibility in IoT is left to the developers and engineers of the individual IoT services and systems. Accessibility is, if at all, designed on the application level, relying on the skill and expertise of individual developers. There is no architecture or framework support for Inclusive IoT Design (e.g. guidance for vision, sound, motion or haptic designs). There are efforts for data interoperability between devices, yet sensing (activity recognition) and accessibility assessment in terms of interacting with the IoT are not covered by them.`}</p>
      <h2>{`motivation`}</h2>
      <p>{`Inclusive Design and Accessibility are two core concepts emphasized in nearly all 17 of the UN’s Sustainable Development Goals (see Figure 1). IoT’s universal impact has a unique potential to serve as an enabling technology for several of the SDGs such as SDG 3, 9 and 11. For IoT to fulfill its potential in advancing social good, accessibility for differently abled users would be essential. People who do not fit the idea of an “average user” (e.g. having some disabilities or being neurodiverse) can benefit immensely from the Internet of Things. For example, mainstream home automation products and services could improve the quality and reduce the cost of custom-made solutions for ambient assisted living, which has been a field of accessibility research and development for decades. However, not considering accessibility in IoT design can further exclude people with different abilities (for example, a touch screen to control the aircon or an home automation smart phone app could be even less accessible than a physical controls, if it does not follow accessibility considerations). Accessibility considerations include the UI design, the protocols and APIs, as well as the subjective user experience.`}</p>
    </Grid>
    <Grid sx={{
      gridTemplateColumns: ['1fr', '1fr', '1fr 1fr']
    }} mdxType="Grid">
      <Box mdxType="Box">
        <h2>{`Contact`}</h2>
        <Heading variant="styles.h3" sx={{
          mb: 0
        }} mdxType="Heading">
  Email
        </Heading>
        <Link href="mailto:kai (at) kmd.keio.ac.jp" variant="styles.a" mdxType="Link">
  kai (at) kmd.keio.ac.jp
        </Link>
        <Heading variant="styles.h3" sx={{
          mb: 0
        }} mdxType="Heading">
  Homepage
        </Heading>
        <Link href="https://kaikunze.de" variant="styles.a" mdxType="Link">
  https://kaikunze.de/
        </Link>
      </Box>
      <Box mdxType="Box">
        <h2>{`Top 5 tags`}</h2>
        <SourceTags filter="posts" mdxType="SourceTags">
  {tags => <Flex sx={{
            flexDirection: 'column'
          }} mdxType="Flex">
      {tags.splice(0, 5).sort((a, b) => b.percent - a.percent).map((tag, index) => {
              const {
                name,
                count,
                percent
              } = tag;
              return <Box key={index} sx={{
                color: 'muted',
                mb: 3,
                svg: {
                  fill: 'muted'
                }
              }} mdxType="Box">
              <Flex sx={{
                  lineHeight: 'normal'
                }} mdxType="Flex">
                <Text sx={{
                    color: 'secondary',
                    mr: 2,
                    mb: 0
                  }} mdxType="Text">{`${name}`}</Text>
                <Text sx={{
                    color: 'muted'
                  }} mdxType="Text">{percent}</Text>
                <Text sx={{
                    color: 'error'
                  }} mdxType="Text">%</Text>
              </Flex>
              <Flex mdxType="Flex">
                <Box mdxType="Box">{'['}</Box>
                <Box sx={{
                    flexBasis: '100%'
                  }} mdxType="Box">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 8" width="100%" height="8">
                    <rect width={`${percent * 3}%`} height={4} />
                  </svg>
                </Box>
                <Box mdxType="Box"> {']'}</Box>
              </Flex>
            </Box>;
            })}
    </Flex>}
        </SourceTags>
      </Box>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      