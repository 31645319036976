import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/kai/syncthing/sync/current/jst-presto-2021-accessibility/web/accessible-iot-source/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Towards an Inclusive Internet of Things.`}</h1>
    <p>{`People who are not correspond to an “average user” (e.g. having some disabilities or being neurodiverse) can benefit immensely from the Internet of Things. However, there are also novel perils in terms of accessibility, as computing becomes more and more integrated in our everyday lives. As interfaces vanish, it gets harder and harder to evaluate their usability and find potential accessibility problems. Additionally, the current IoT infrastructure does not provide much help for developers, engineers, and system designers to develop accessible/inclusive IoT services. So far, this is up to the individual development team and their expertise on that matter. Inclusive IoT guidelines or frameworks are lacking or nonexistent. To tackle these issues, this proposal is a first step towards an Inclusive IoT Infrastructure supporting an unobtrusive accessibility assessment of IoT services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      